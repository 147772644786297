/* 
---------------------------------------------
Autocomplete
--------------------------------------------- 
*/

.combo_box {
  margin: 0;
  padding: 0;
  background-color: white;
}

.combo_item {
  display: flex;
  align-items: center; /* Alinea verticalmente la imagen y el texto */
}

.combo_item img {
  width: 50px; /* Tamaño de la imagen */
  height: 50px; /* Tamaño de la imagen */
  margin-right: 10px; /* Espacio entre la imagen y el texto */
}

.combo_option {
  flex-grow: 1;
  color: var(--liberty-blue);
  padding: 10px;
  text-decoration: none;
  list-style-type: none;
}

/* Estilos para hover */
.combo_item:hover {
  /* Cambia el color de fondo al pasar el mouse */
  background-color: var(--liberty-blue);
}

.combo_item:hover .combo_option {
  color: var(--white-action);
}
