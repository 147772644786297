/* 
---------------------------------------------
Event Location
--------------------------------------------- 
*/

.content {
  width: 100%;
  height: 100%;
}

.loader_wrapper {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    display: flex; /* Habilita Flexbox */
    align-items: center; /* Centra los elementos verticalmente */
    justify-content: center; /* Centra los elementos horizontalmente */
  }
  
  .loader_content {
    width: 600px;
    text-align: center;
    margin: 20px;
    padding: 20px;
    border-radius: 16px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .loader_content p {
    font-weight: lighter;
  }
  
  .loader_content img {
    width: 200px;
    height: 200px;
  }

  .location_content {
    margin: 0 auto;
    padding: 10px;
    box-shadow: none;
  }

  .location_section {
    flex-direction: column; /* Cambia la dirección del flexbox a vertical */
  }

  .left_section {
    flex: 2;
  }

  .right_section {
    flex: 1;
    margin: 10px;
    padding: 10px;
    border-radius: 12px;
    border: 1px solid var(--liberty-blue);
  }
  
  .right_section p {
    font-weight: lighter;
    font-size: 26px;
  }

  .map_section {
    width: 100%;
    height: 500px;
    background-color: var(--white-action);
  }

  .border_content {
    border: 1px solid var(--liberty-blue);
    margin: 8px;
    padding: 12px;
    border-radius: 12px;
  }
  
  .border_content p {
    font-weight: lighter;
  }