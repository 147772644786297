/* 
---------------------------------------------
My events
--------------------------------------------- 
*/

.content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column; /* Ordena los elementos en una columna */
  align-items: flex-start; /* Alinea los elementos a la izquierda */
}

.item_header {
  display: flex;
  align-items: center; /* Alinea el icono y el texto verticalmente */
}

.item_header img {
  width: 48px;
  height: 48px;
}

.item_header img:hover {
  cursor: pointer;
}

.content h1 {
  font-weight: bold;
  font-size: 36px;
}

.content h2 {
  font-weight: normal;
  font-size: 26px;
  margin: 0;
  padding: 0;
}
