/* 
---------------------------------------------
Payment
--------------------------------------------- 
*/

.loader {
  display: flex;
  flex-direction: column; /* Alinea los hijos verticalmente */
  justify-content: center; /* Centra el contenido verticalmente */
  align-items: center; /* Centra el contenido horizontalmente */
  width: 100%; /* Ancho completo de la pantalla */
  height: 550px; /* Altura fija de 750px */
}

.loader h1,
h2,
p {
  margin: 10px; /* Agrega espaciado entre elementos */
}

.content {
  width: 100%; /* Abarca todo el ancho de la pantalla */
  height: 100%;
}

.success_content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 600px;
}

.payment_success {
  text-align: center;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 600px;
}

.payment_success img {
  width: 200px;
  height: 200px;
}

.payment_success p {
  font-weight: lighter;
}
