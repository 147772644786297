/* 
---------------------------------------------
Header
--------------------------------------------- 
*/

.main {
  width: 100%;
  height: 100%;
}

.header {
  height: 125px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 16px;
  padding-right: 16px;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -2px rgba(0, 0, 0, 0.1); /* Sombra para el header */
}

.logo {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: var(--text-color);
}

.logo img {
  width: 75px; /* Ajusta el tamaño de tu logo */
  height: auto;
  margin-right: 10px;
}

.logo span {
  font-size: 45px;
  font-weight: bold;
}

/* 
---------------------------------------------
Sign Up
---------------------------------------------
*/

.content {
  display: flex; /* Habilita Flexbox */
  flex-direction: column; /* Organiza los hijos en una columna */
  justify-content: center; /* Centra los hijos verticalmente */
  align-items: center; /* Centra los hijos horizontalmente */
  margin: 50px auto;
  height: 100%;
  width: 950px;
  border-radius: 16px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.content h1 {
  font-size: 45px;
  margin: 10px;
}

.inner_content {
  display: flex;
  margin: 25px;
}

.left_content {
  display: flex;
  flex-direction: column;
  justify-content: baseline; /* Centra verticalmente los elementos */
  align-items: center; /* Centra horizontalmente los elementos */
  margin-right: 16px;
}

.left_content img {
  width: 250px;
  height: 250px;
  margin: 10px;
  object-fit: cover;
  border-radius: 50%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.right_content {
  flex-grow: 1; /* El div se expande para ocupar el espacio restante */
  color: var(--text-color);
  width: 100%;
}

.right_content label {
  font-weight: lighter;
}

.right_content h3 {
  color: var(--text-color);
  font-size: 18px;
}

.right_content input {
  font-size: large;
  padding: 10px;
}

.password_hint {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 16px;
}

.password_hint p {
  font-weight: bold;
  font-size: 18px;
}

.password_hint ul {
  list-style-type: none;
}

.password_hint ul li {
  font-weight: lighter;
  font-size: 16px;
}

.check_box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  padding: 6px;
}

.check_box p {
  font-weight: lighter;
  font-size: 16px;
}

.check_box button {
  background-color: transparent;
}

.check_box p button {
  font-weight: bold;
  font-size: 18px;
  border: none;
  background: none;
  outline: none;
  cursor: pointer;
  color: var(--liberty-blue);
}

.gender_select {
  width: 100%;
  padding: 12px 5px;
  margin: 4px 0px;
  box-sizing: border-box;
  border: 2px solid var(--liberty-blue);
}

/* 
---------------------------------------------
Footer
--------------------------------------------- 
*/

.footer {
  position: relative; /* Establece el posicionamiento relativo en el footer */
  height: 350px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  background: url("./../../../../public/img/bottom-dec.png");
  background-repeat: no-repeat;
  background-position: 0 auto;
  background-size: 100% auto;
}

.footer_content {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  text-align: right; /* Alinea el texto a la derecha */
}

.footer_content p {
  color: var(--white-action);
  text-align: right;
  margin: 5px;
  padding-bottom: 5px;
}

.footer_links {
  display: flex;
  align-items: center;
  gap: 30px; /* Espacio entre los elementos del segundo div */
}

.footer_links a {
  display: block;
  color: var(--white-action);
  text-decoration: none;
}

.footer_links a:hover {
  text-decoration: underline;
}

.org {
  font-style: italic;
  color: var(--white-action);
}

/*
---------------------------------------------
---------------------------------------------

Media Query

---------------------------------------------
--------------------------------------------- 
*/

/* Media query para dispositivos  móviles */
@media only screen and (max-width: 650px) {
  .content {
    width: 100%;
    box-shadow: none;
  }

  .header {
    height: auto;
    flex-direction: column; /* Cambia la dirección de los elementos a vertical */
    align-items: flex-start; /* Alinea los elementos al principio */
  }

  .logo {
    margin-bottom: 10px; /* Añade un margen inferior al logo */
  }

  .header_buttons button {
    margin-left: 0; /* Elimina el margen izquierdo de los botones */
    margin-top: 10px; /* Añade un margen superior a los botones */
    margin-bottom: 10px;
  }

  .inner_content {
    flex-direction: column; /* Cambia la disposición a vertical */
  }

  .left_content,
  .right_content {
    width: 100%; /* Ajusta el ancho al 100% para cada sub-div */
    margin-right: 0; /* Elimina el margen derecho, si existiera, para que no afecte en móviles */
    align-items: center; /* Asegura que los contenidos internos están centrados */
    margin-bottom: 15px;
  }

  .left_content img {
    width: 200px; /* Opcional: Ajusta el tamaño de la imagen para dispositivos más pequeños */
    height: 200px; /* Opcional: Ajusta el tamaño de la imagen para dispositivos más pequeños */
  }

  .footer {
    height: 180px; /* Altura automática para adaptarse al contenido */
  }
}
