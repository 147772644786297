/*
---------------------------------------------
General
--------------------------------------------- 
*/
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;500&display=swap");

:root {
  --liberty-blue: #3f4b97;
  --text-color: #333333;
  --white-action: #f2f2f2;
}

* {
  font-family: Roboto;
  border: 1px solid transparent;
}

body,
html {
  margin: 0;
  padding: 0;
}

/* 
---------------------------------------------
Buttons
--------------------------------------------- 
*/

.border_button {
  color: var(--liberty-blue);
  font-size: 16px;
  font-weight: bolder;
  background-color: white;
  border: 2px solid var(--liberty-blue);
  padding: 10px 20px 10px 20px;
  margin: 0px 10px 0px 10px;
  text-decoration: none;
  border-radius: 10px;
  cursor: pointer;
}

.solid_button {
  color: var(--white-action);
  font-size: 16px;
  font-weight: lighter;
  background-color: var(--liberty-blue);
  padding: 10px 20px 10px 20px;
  border-radius: 10px;
  cursor: pointer;
}

.label_button {
  font-weight: bold;
  font-size: 18px;
  border: none;
  background: none;
  outline: none;
  cursor: pointer;
  color: var(--liberty-blue);
  margin: 10px;
}

/* 
---------------------------------------------
Common
--------------------------------------------- 
*/

.message {
  width: 100%;
  font-size: xx-large;
  margin: 0 auto;
  text-align: center;
  margin-top: 16px;
}

textarea {
  box-sizing: border-box;
  border: 2px solid var(--liberty-blue);
}

.box-shadow {
  padding: 10px;
  border-radius: 16px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* 
---------------------------------------------
Input
---------------------------------------------
*/

input {
  width: 100%;
  padding: 12px 5px;
  margin: 4px 0px;
  box-sizing: border-box;
  border: 2px solid var(--liberty-blue);
}

.visibility-input {
  width: 100%;
  display: flex;
  align-items: center; /* Alinea los elementos verticalmente */
}

.visibility-input input[type="text"] {
  flex: 1; /* El input text toma el espacio restante */
}

.visibility-input button {
  border: none; /* Quita el borde del botón */
  padding: 0; /* Quita el relleno del botón */
  cursor: pointer; /* Cambia el cursor al pasar por encima del botón */
  width: 40px; /* Establece un ancho fijo para el botón */
  height: 40px; /* Establece una altura fija para el botón */
}

.visibility_on {
  background-color: transparent;
  background-image: url("../public/img/visibility_on.png");
  background-size: 30px; /* Para ajustar la imagen al tamaño del botón */
  background-repeat: no-repeat; /* Para que la imagen no se repita */
  background-position: center;
}

.visibility_off {
  background-color: transparent;
  background-image: url("../public/img/visibility_off.png");
  background-size: 30px; /* Para ajustar la imagen al tamaño del botón */
  background-repeat: no-repeat; /* Para que la imagen no se repita */
  background-position: center;
}