/* 
---------------------------------------------
Header
--------------------------------------------- 
*/

.main {
  width: 100%;
  height: 100%;
}

.header {
  height: 125px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 16px;
  padding-right: 16px;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -2px rgba(0, 0, 0, 0.1); /* Sombra para el header */
}

.logo {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: var(--text-color);
}

.logo img {
  width: 75px; /* Ajusta el tamaño de tu logo */
  height: auto;
  margin-right: 10px;
}

.logo span {
  font-size: 45px;
  font-weight: bold;
}

.selector {
  position: relative; /* Posiciona relativo al área asignada */
}

.selector_button {
  color: var(--liberty-blue);
  font-size: 16px;
  font-weight: bolder;
  background-color: white;
  border: 2px solid var(--liberty-blue);
  padding: 5px 10px 5px 10px;
  margin: 0px 5px 0px 5px;
  text-decoration: none;
  border-radius: 10px;
  cursor: pointer;
}

.dropdown_menu {
  position: absolute; /* Posiciona absolutamente respecto a su contenedor */
  right: 0; /* Alinea el menú a la derecha */
  top: 100%; /* Coloca el menú justo debajo del botón */
  background-color: white; /* Fondo blanco para el menú */
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1000; /* Asegura que el menú se muestre por encima de otros contenidos */
  width: 200px; /* Ancho fijo para la lista desplegable */
}

.dropdown_menu li {
  padding: 8px 16px; /* Añade padding para mejor toque visual */
  cursor: pointer;
  list-style: none; /* Elimina estilos de lista */
}

.dropdown_menu li:hover {
  background-color: #f5f5f5; /* Cambio de color al pasar el ratón */
}

/* 
  ---------------------------------------------
  Top section
  --------------------------------------------- 
  */

.top_section {
  height: 700px;
  background: url("./../../../../public/img/hand.png") no-repeat scroll;
  background-position: right;
}

.top_article {
  width: 50%;
  height: 60%;
  margin-left: 65px;
  margin-top: 65px;
}

.top_article h1 {
  font-size: 75px; /* Tamaño de fuente */
  font-weight: bold; /* Peso de fuente */
}

.top_article p {
  font-size: 28px; /* Tamaño de fuente */
  font-weight: lighter; /* Peso de fuente */
}

.app_links {
  margin-top: 25px;
  display: flex;
  flex-direction: row;
}

.app_links a {
  text-decoration: none;
}

/* 
  ---------------------------------------------
  Middle section
  --------------------------------------------- 
  */

.middle_section {
  height: 700px;
  background: url("./../../../../public/img/left-dec.png") no-repeat scroll;
  background-position: left;
  background-size: contain;
}

.middle_article {
  width: 50%;
  margin-left: 40%;
  margin-right: 10%;
}

.middle_article h1 {
  font-size: 75px; /* Tamaño de fuente */
  font-weight: bold; /* Peso de fuente */
}

.middle_article p {
  font-size: 28px; /* Tamaño de fuente */
  font-weight: lighter; /* Peso de fuente */
}

/* 
  ---------------------------------------------
  Last section
  --------------------------------------------- 
  */

.last_section {
  height: 400px;
  background: url("./../../../../public/img/center-dec.png") no-repeat scroll;
  background-position: center;
  background-size: contain;
}

/* 
  ---------------------------------------------
  Footer
  --------------------------------------------- 
  */

.footer {
  position: relative; /* Establece el posicionamiento relativo en el footer */
  height: 350px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  background: url("./../../../../public/img/bottom-dec.png");
  background-repeat: no-repeat;
  background-position: 0 auto;
  background-size: 100% auto;
}

.footer_content {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  text-align: right; /* Alinea el texto a la derecha */
}

.footer_content p {
  color: var(--white-action);
  text-align: right;
  margin: 5px;
  padding-bottom: 5px;
}

.footer_links {
  display: flex;
  align-items: center;
  gap: 30px; /* Espacio entre los elementos del segundo div */
}

.footer_links a {
  display: block;
  color: var(--white-action);
  text-decoration: none;
}

.footer_links a:hover {
  text-decoration: underline;
}

.org {
  font-style: italic;
  color: var(--white-action);
}

/* 
  ---------------------------------------------
  ---------------------------------------------
  
  Media Query
  
  ---------------------------------------------
  --------------------------------------------- 
  */

/* Media query para dispositivos  móviles */
@media only screen and (max-width: 650px) {
  .header {
    height: auto;
    flex-direction: column; /* Cambia la dirección de los elementos a vertical */
    align-items: flex-start; /* Alinea los elementos al principio */
  }

  .logo {
    margin-bottom: 10px; /* Añade un margen inferior al logo */
  }

  .header_buttons button {
    margin-left: 0; /* Elimina el margen izquierdo de los botones */
    margin-top: 10px; /* Añade un margen superior a los botones */
    margin-bottom: 10px;
  }

  .top_section {
    height: auto; /* Altura automática para adaptarse al contenido */
    background-position: top; /* Cambia la posición del fondo */
    background-size: 300px;
  }

  .top_article {
    width: 100%; /* Ancho del 90% para ajustarse mejor en pantallas pequeñas */
    margin: 10px auto; /* Margen automático para centrar */
  }

  .top_article h1 {
    font-size: 45px; /* Reducción del tamaño de fuente */
  }

  .top_article p {
    font-size: 20px; /* Reducción del tamaño de fuente */
  }

  .app_links {
    margin-top: 15px;
    width: auto;
    flex-direction: column;
    align-items: flex-start;
  }

  .app_links a {
    margin: 5px;
  }

  .middle_section {
    height: auto; /* Altura automática para adaptarse al contenido */
    background-position: left; /* Cambia la posición del fondo */
  }

  .middle_article {
    width: 90%; /* Ancho del 90% para ajustarse mejor en pantallas pequeñas */
    margin: 20px auto; /* Margen automático para centrar */
  }

  .middle_article h1 {
    font-size: 45px; /* Reducción del tamaño de fuente */
  }

  .middle_article p {
    font-size: 20px; /* Reducción del tamaño de fuente */
  }

  .last_section {
    height: auto; /* Altura automática para adaptarse al contenido */
  }

  .footer {
    height: 180px; /* Altura automática para adaptarse al contenido */
  }
}
