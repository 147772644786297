/* 
---------------------------------------------
Header
--------------------------------------------- 
*/

.main {
  width: 100%;
  height: 100%;
}

.header {
  height: 125px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 16px;
  padding-right: 16px;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -2px rgba(0, 0, 0, 0.1); /* Sombra para el header */
}

.logo {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: var(--text-color);
}

.logo img {
  width: 75px; /* Ajusta el tamaño de tu logo */
  height: auto;
  margin-right: 10px;
}

.logo span {
  font-size: 45px;
  font-weight: bold;
}

/* 
---------------------------------------------
Sign In
---------------------------------------------
*/

.content {
  display: flex; /* Habilita Flexbox */
  flex-direction: column; /* Organiza los hijos en una columna */
  justify-content: center; /* Centra los hijos verticalmente */
  align-items: center; /* Centra los hijos horizontalmente */
  margin: 50px auto;
  height: 500px;
  width: 500px;
  border-radius: 16px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.content h1 {
  font-size: 65px;
}

.content input {
  font-size: large;
  margin: 10px; /* Espacio entre los campos de texto y el botón */
}

.content button {
  margin: 10px;
}

/* 
---------------------------------------------
Footer
--------------------------------------------- 
*/

.footer {
  position: relative; /* Establece el posicionamiento relativo en el footer */
  height: 350px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  background: url("./../../../../public/img/bottom-dec.png");
  background-repeat: no-repeat;
  background-position: 0 auto;
  background-size: 100% auto;
}

.footer_content {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  text-align: right; /* Alinea el texto a la derecha */
}

.footer_content p {
  color: var(--white-action);
  text-align: right;
  margin: 5px;
  padding-bottom: 5px;
}

.footer_links {
  display: flex;
  align-items: center;
  gap: 30px; /* Espacio entre los elementos del segundo div */
}

.footer_links a {
  display: block;
  color: var(--white-action);
  text-decoration: none;
}

.footer_links a:hover {
  text-decoration: underline;
}

.org {
  font-style: italic;
  color: var(--white-action);
}

/*
---------------------------------------------
---------------------------------------------

Media Query

---------------------------------------------
--------------------------------------------- 
*/

/* Media query para dispositivos  móviles */
@media only screen and (max-width: 650px) {
  .header {
    height: auto;
    flex-direction: column; /* Cambia la dirección de los elementos a vertical */
    align-items: flex-start; /* Alinea los elementos al principio */
  }

  .logo {
    margin-bottom: 10px; /* Añade un margen inferior al logo */
  }

  .header_buttons button {
    margin-left: 0; /* Elimina el margen izquierdo de los botones */
    margin-top: 10px; /* Añade un margen superior a los botones */
    margin-bottom: 10px;
  }

  .content {
    box-shadow: none; /* Elimina el box-shadow para dispositivos móviles */
  }

  .footer {
    height: 180px; /* Altura automática para adaptarse al contenido */
  }
}
