/* 
---------------------------------------------
Header
--------------------------------------------- 
*/

.main {
  width: 100%;
  height: 100%;
}

.header {
  height: 125px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 16px;
  padding-right: 16px;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -2px rgba(0, 0, 0, 0.1); /* Sombra para el header */
}

.logo {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: var(--text-color);
}

.logo img {
  width: 75px; /* Ajusta el tamaño de tu logo */
  height: auto;
  margin-right: 10px;
}

.logo span {
  font-size: 45px;
  font-weight: bold;
}

/* 
---------------------------------------------
Home
--------------------------------------------- 
*/

.header_buttons img {
  width: 48px;
  height: 48px;
  background-color: transparent;
}

.profile_menu {
  position: absolute;
  width: 325px;
  background-color: white;
  border: 1px solid var(--liberty-blue);
  right: 16px;
  top: 10px;
  display: none;
}

.profile_menu_info {
  display: flex; /* Usa flexbox para alinear verticalmente */
  align-items: center; /* Alinea los elementos verticalmente */
  justify-content: right; /* Alinea los elementos horizontalmente */
  height: 85px; /* Altura del contenedor */
  margin: 5px;
}

.profile_menu_info h2 {
  margin: 0 10px;
}

/* Estilos para la imagen */
.profile_menu_info img {
  width: 80px; /* Ancho de la imagen */
  height: 80px;
  margin-right: 10px; /* Margen derecho para separar la imagen del texto */
  border-radius: 50%;
  object-fit: cover;
}

.profile_menu_option {
  display: flex; /* Usa flexbox para distribuir los elementos */
}

.profile_menu_list {
  flex: 1; /* Cada contenedor ocupa la mitad del ancho disponible */
  margin: 0 5px; /* Espacio entre los contenedores */
}

.profile_menu_list ul {
  list-style: none; /* Quita los marcadores de la lista */
  padding: 0; /* Elimina el relleno predeterminado */
}

.profile_menu_list ul li {
  display: flex; /* Usa flexbox para alinear imagen y texto */
  align-items: center; /* Alinea verticalmente los elementos */
  margin-bottom: 5px; /* Espacio entre los ítems */
  padding: 5px 0;
  cursor: pointer;
}

.profile_menu_list ul li img {
  width: 22px;
  height: 22px;
  margin-right: 5px;
}

/* Mostrar el menú cuando se pasa el cursor sobre el icono */
.header_buttons img:hover + .profile_menu,
.profile_menu:hover {
  display: block;
}

.modal_code {
  display: flex; /* Utiliza Flexbox para la disposición de los elementos */
  flex-direction: column; /* Alinea los elementos en dirección vertical */
  align-items: center; /* Centra los elementos horizontalmente */
  justify-content: center; /* Centra los elementos verticalmente si es necesario */
  text-align: center; /* Alinea el texto al centro */
}

.modal_code img {
  width: 100px; /* Establece el ancho de la imagen */
  height: 100px; /* Establece la altura de la imagen */
  object-fit: cover; /* Asegura que la imagen cubra completamente el área sin deformarse */
  margin-bottom: 10px; /* Añade un espacio debajo de la imagen */
  border-radius: 50%;
}

/* 
---------------------------------------------
Footer
--------------------------------------------- 
*/

.footer {
  position: relative; /* Establece el posicionamiento relativo en el footer */
  height: 350px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  background: url("./../../../public/img/bottom-dec.png");
  background-repeat: no-repeat;
  background-position: 0 auto;
  background-size: 100% auto;
}

.footer_links {
  display: flex;
  align-items: center;
  gap: 30px; /* Espacio entre los elementos del segundo div */
}

.footer_links a {
  display: block;
  color: var(--white-action);
  text-decoration: none;
}

.footer_links a:hover {
  text-decoration: underline;
}

.org {
  font-style: italic;
  color: var(--white-action);
}

/*
---------------------------------------------
---------------------------------------------

Media Query

---------------------------------------------
--------------------------------------------- 
*/

/* Media query para dispositivos  móviles */
@media only screen and (max-width: 650px) {
  .profile_menu {
    right: 0; /* Alinear el menú a la derecha */
    left: 0; /* Alinear el menú a la izquierda */
    max-width: none; /* Eliminar la anchura máxima para ocupar todo el ancho disponible */
    width: 100%; /* Ocupa todo el ancho disponible */
  }

  .footer {
    height: 180px; /* Altura automática para adaptarse al contenido */
  }
}