/* 
---------------------------------------------
Header
--------------------------------------------- 
*/

.main {
  width: 100%;
  height: 100%;
}

.header {
  height: 125px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 16px;
  padding-right: 16px;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -2px rgba(0, 0, 0, 0.1); /* Sombra para el header */
}

.logo {
  text-decoration: none; /* Quita el subrayado predeterminado del enlace */
  color: var(--text-color);
  display: flex; /* Usa flexbox para alinear elementos */
  align-items: center; /* Alinea los elementos verticalmente */
}

.logo img {
  width: 75px; /* Ajusta el tamaño de tu logo */
  height: auto;
  margin-right: 10px;
}

.logo span {
  font-size: 45px;
  font-weight: bold;
}

/* 
---------------------------------------------
Legal
---------------------------------------------
*/

.document {
  height: 100%;
  width: 65%;
  margin: 0 auto;
  text-align: justify;
}

.document h1,
.document h2,
.document h3,
.document h4 {
  color: var(--text-color);
}

.document p {
  color: var(--text-color);
  text-align: justify;
}

/* 
---------------------------------------------
Footer
--------------------------------------------- 
*/

.footer {
  position: relative; /* Establece el posicionamiento relativo en el footer */
  height: 350px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  background: url("./../../../../public/img/bottom-dec.png");
  background-repeat: no-repeat;
  background-position: 0 auto;
  background-size: 100% auto;
}

.footer_content {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  text-align: right; /* Alinea el texto a la derecha */
}

.footer_content p {
  color: var(--white-action);
  text-align: right;
  margin: 5px;
  padding-bottom: 5px;
}

.footer_links {
  display: flex;
  align-items: center;
  gap: 30px; /* Espacio entre los elementos del segundo div */
}

.footer_links a {
  display: block;
  color: var(--white-action);
  text-decoration: none;
}

.footer_links a:hover {
  text-decoration: underline;
}

.org {
  font-style: italic;
  color: var(--white-action);
}

/* 
---------------------------------------------
---------------------------------------------

Media Query

---------------------------------------------
--------------------------------------------- 
*/

/* Media query para dispositivos  móviles */
@media only screen and (max-width: 650px) {

  .document {
    width: 80%; /* Cambiar el ancho al 100% para adaptarse a dispositivos móviles */
    padding: 20px; /* Agregar un poco de espaciado interior */
  }

  .footer {
    height: 180px; /* Altura automática para adaptarse al contenido */
  }
}
