/* 
---------------------------------------------
Event Description
--------------------------------------------- 
*/

.content {
  width: 100%;
  min-height: 600px;
}

.container {
  display: flex;
  width: 90%;
  height: auto;
  margin: 20px auto;
  padding: 20px;
  border-radius: 16px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.half_container {
  width: 50%;
  display: flex; /* Activa Flexbox */
  flex-direction: column; /* Organiza los hijos en una columna vertical */
  justify-content: center; /* Centra los elementos verticalmente */
  align-items: center; /* Centra los elementos horizontalmente */
}

.half_container p {
  font-weight: lighter;
  text-align: justify;
  font-size: 14px;
}

.half_container textarea {
  width: 100%; /* Hace que el textarea tome el 100% del ancho del contenedor padre */
  box-sizing: border-box; /* Asegura que el padding y el borde sean incluidos en el cálculo del ancho */
  padding: 8px; /* Añade un poco de padding para mejor legibilidad */
  border: 1px solid var(--white-action);
  resize: vertical; /* Permite al usuario ajustar solo la altura del textarea */
}

.image_container {
  width: 400px;
  height: 550px;
  display: flex; /* Habilita Flexbox en el contenedor de la imagen */
  justify-content: center; /* Centra la imagen horizontalmente */
  align-items: center; /* Centra la imagen verticalmente */
}

.image_container img {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Para asegurar que la imagen se ajuste dentro del contenedor */
  border-radius: 16px;
}

/* 
---------------------------------------------
---------------------------------------------

Media Query

---------------------------------------------
--------------------------------------------- 
*/

/* Media query para dispositivos con un ancho máximo de 768px (típicamente dispositivos móviles) */
@media only screen and (max-width: 650px) {
  .content {
    width: 100%;
    min-height: 600px;
    margin: 0;
    padding: 0;
  }

  .container {
    display: flex;
    flex-direction: column; /* Cambia la disposición a vertical para dispositivos móviles */
    width: auto;
    height: auto;
    margin: 10px;
    padding: 10px;
    border-radius: 16px;
    box-shadow: none;
  }

  .half_container {
    width: 100%; /* Ocupa todo el ancho disponible en dispositivos móviles */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px; /* Añade un margen inferior para separar los contenidos */
  }

  .half_container p,
  .half_container textarea,
  .half_container input[type="file"],
  .half_container button {
    width: 100%; /* Asegura que todos los elementos ocupen el 100% del ancho del contenedor */
  }

  .image_container {
    width: 100%; /* Ajusta el ancho al 100% para adaptarse a dispositivos móviles */
    height: auto; /* Ajusta la altura automáticamente para mantener la proporción */
  }

  .image_container img {
    width: 300px;
    height: 450px; /* Ajusta la altura automáticamente para mantener la proporción */
    object-fit: cover; /* Mantiene la proporción de la imagen sin recortarla */
    border-radius: 16px;
  }
}
