/* 
---------------------------------------------
QR Scanner
--------------------------------------------- 
*/
.scanner_content {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column; /* Organiza los hijos en una columna (verticalmente) */
  align-items: center; /* Centra los elementos horizontalmente */
  justify-content: center; /* Opcional: si quieres que también estén centrados verticalmente en el contenedor */
}

.scanner_container {
  height: 600px;
  width: 600px;
  display: flex;
  flex-direction: column; /* Organiza los hijos en una columna (verticalmente) */
  align-items: center; /* Centra los elementos horizontalmente */
  justify-content: center; /* Opcional: si quieres que también estén centrados verticalmente en el contenedor */
  text-align: center;
  padding: 25px;
  margin: 25px;
  border-radius: 16px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
}

.profile_modal {
  text-align: center;
}

.profile_modal img {
  width: 175px;
  height: 175px;
  object-fit: cover;
  border-radius: 50%;
}

.profile_modal h2 {
  text-align: center;
  color: var(--white-action);
}

.profile_modal h3 {
  font-size: 32px;
  text-align: center;
  color: var(--white-action);
}

/*
---------------------------------------------
---------------------------------------------

Media Query

---------------------------------------------
--------------------------------------------- 
*/

/* Media query para dispositivos  móviles */
@media only screen and (max-width: 650px) {
  .scanner_container {
    width: 500px; /* Tamaño fijo para pantallas grandes */
    height: 500px; /* Tamaño fijo para pantallas grandes */
    box-shadow: none;
  }
}
