/* 
---------------------------------------------
Active event
--------------------------------------------- 
*/

.content {
  width: 100%;
  height: 100%;
}

.buttons_content {
  display: flex;
  align-items: center; /* Alinea verticalmente los botones */
  justify-content: space-between; /* Alinea el primer grupo de botones a la izquierda y el último botón a la derecha */
  height: 50px; /* Altura específica del div */
}

.buttons_group {
  display: flex; /* Alinea los botones Públicos y Privados juntos */
  gap: 10px; /* Espacio entre los botones dentro del grupo */
}

.border_content {
  border: 1px solid var(--liberty-blue);
  margin: 8px;
  padding: 12px;
  border-radius: 12px;
}

.border_content p {
  font-weight: lighter;
}
