/* 
---------------------------------------------
Invitation Item
---------------------------------------------
*/

.loader {
  width: 200px;
  text-align: center;
  margin: 10px;
  padding: 10px;
}

.loader img {
  width: 75px;
  height: 75px;
}

.content {
  width: 400px;
  height: 550px;
  background-color: var(--white-action);
  margin-right: 20px; /* Agrega un margen entre los elementos */
  margin-bottom: 20px; /* Agrega espacio entre las filas de elementos */
  border-radius: 16px;
}

.send_appearance {
  position: relative;
  width: 400px;
  height: 550px;
  /* Para recortar la imagen si es más grande que el contenedor */
  overflow: hidden;
  background-image: url("./../../../../public/img/background.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  /* Elimina el margen predeterminado del body */
  margin: 0;
  /* Elimina el relleno predeterminado del body */
  padding: 0;
  border-radius: 16px;
}

.send_content {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center; /* Centra verticalmente los elementos */
  align-items: center; /* Centra horizontalmente los elementos */
}

.send_content h2 {
  color: var(--white-action);
}

.send_content button {
  width: 100px;
  height: 100px;
  font-size: 24px; /* Tamaño grande del botón */
  background-color: transparent;
  cursor: pointer;
  margin: 0;
  padding: 0;
  background-image: url("./../../../../public/img/inbox_icon.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.accepted_content {
  position: relative;
  width: 400px;
  height: 550px;
  margin: 0; /* Elimina el margen predeterminado del body */
  padding: 0; /* Elimina el relleno predeterminado del body */
}

.accepted_content img {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Para asegurar que la imagen se ajuste dentro del contenedor */
  object-position: top center;
  border-radius: 16px;
}

.accepted_info {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: var(--white-action);
}

.accepted_info h2 {
  color: var(--white-action);
}

.accepted_info h3 {
  color: var(--white-action);
}

.modal {
  width: 100%;
  height: auto;
  position: relative; /* Posicionamiento relativo para que los elementos dentro se posicionen respecto a este div */
  color: var(--white-action);
}

.modal img {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Para asegurar que la imagen se ajuste dentro del contenedor */
}

.modal_content {
  position: absolute; /* Posicionamiento absoluto para que los elementos de contenido se posicionen sobre la imagen */
  height: 100%;
  width: 100%;
  top: 50%; /* Desplazamiento hacia abajo al 50% del contenedor */
  left: 50%; /* Desplazamiento hacia la derecha al 50% del contenedor */
  transform: translate(-50%, -50%); /* Centrar el contenido del div */
  text-align: left;
  overflow: auto; /* Habilita el scroll si el contenido excede las dimensiones del div */
}

.modal_code {
  text-align: center;
}

.modal_info {
  color: var(--white-action);
}

.modal_info h1 {
  font-size: 42px; /* Tamaño más grande para el h1 */
}

.modal_info h2 {
  font-size: 28px; /* Tamaño más pequeño para el h2 */
  margin: 0;
  padding: 0;
  color: var(--white-action);
}

.modal_info p {
  text-align: justify; /* Opcional: Justifica el texto del párrafo */
  color: var(--white-action);
}

.modal_location h2 {
  color: var(--white-action);
}

.modal_location p {
  color: var(--white-action);
}

.modal_map {
  width: 100%;
  height: 250px;
}

.modal_profile {
  text-align: center;
}

.modal_profile img {
  width: 175px;
  height: 175px;
  object-fit: cover;
  border-radius: 50%;
}

.modal_profile h2 {
  text-align: center;
  color: var(--white-action);
}

.modal_profile h3 {
  font-size: 32px;
  text-align: center;
  color: var(--white-action);
}

/*
---------------------------------------------
---------------------------------------------

Media Query

---------------------------------------------
--------------------------------------------- 
*/

/* Media query para dispositivos  móviles */
@media only screen and (max-width: 650px) {
  .content {
    width: 200px;
    height: 250px;
  }

  .send_appearance {
    width: 200px;
    height: 250px;
    text-align: center;
  }

  .accepted_content {
    width: 200px;
    height: 250px;
  }
}
