/* 
---------------------------------------------
Edit event
--------------------------------------------- 
*/

.content {
  width: 100%;
  height: 100%;
}

.container {
  display: flex; /* Utiliza Flexbox */
  justify-content: center; /* Alinea los elementos al centro horizontalmente */
  width: 100%;
  height: 100%;
}

.half_container {
  flex: 1; /* Ambos divs ocupan la misma cantidad de espacio */
  height: 100%; /* Ocupa el 100% de la altura del contenedor */
  display: flex; /* Para alinear contenido en el centro verticalmente */
  flex-direction: column; /* Para apilar elementos verticalmente */
  justify-content: center; /* Para alinear contenido en el centro horizontalmente */
  align-items: center; /* Para alinear contenido en el centro verticalmente */
  padding: 10px;
}

.half_container p {
  font-weight: lighter;
  text-align: justify;
  font-size: 14px;
}

.shadow_box {
  padding: 10px;
  border-radius: 16px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.image_preview {
  width: 450px; /* Abarca todo el ancho del div */
  height: 650px; /* Abarca todo el alto del div */
  object-fit: cover; /* Ajusta la imagen para cubrir el div */
  border-radius: 16px;
}

.map_container {
  height: 400px; /* Reduce la altura del mapa en dispositivos móviles */
}

.unlimit_content {
  display: flex;
  flex-direction: column;
}

.unlimit_header {
  display: flex;
  align-items: center;
}

.unlimit_header button {
  background-color: transparent;
}

/* 
---------------------------------------------
---------------------------------------------

Media Query

---------------------------------------------
--------------------------------------------- 
*/

/* Media query para dispositivos con un ancho máximo de 768px (típicamente dispositivos móviles) */
@media only screen and (max-width: 650px) {
  .container {
    flex-direction: column; /* Layout horizontal para pantallas más grandes */
  }

  .half_container {
    flex: 1; /* Distribución equitativa del espacio */
  }

  .image_preview {
    width: 300px;
    height: 450px; /* Ajusta la altura automáticamente para mantener la proporción */
    object-fit: cover; /* Mantiene la proporción de la imagen sin recortarla */
    border-radius: 16px;
  }

  /* Ajusta los textos para mejorar la legibilidad en pantallas pequeñas */
  .shadow_box p,
  .unlimit_content p {
    font-size: 14px; /* Reduce el tamaño de fuente para ahorrar espacio */
  }
}
