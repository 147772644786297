/* 
---------------------------------------------
Guest Item
--------------------------------------------- 
*/

.item {
  width: auto;
  height: auto;
  text-align: center;
}

.item img {
  width: 165px;
  height: 165px;
  border-radius: 50%;
  object-fit: cover;
  margin-top: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
