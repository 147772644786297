/* 
---------------------------------------------
Guests list
--------------------------------------------- 
*/

.content {
  width: 100%;
  height: 100%;
}

/* Estilo para el contenedor principal con scroll */
.main_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  margin: 20px auto;
  width: 90%;
}

/* Estilo para el h1 y el p dentro de main-container */
.main_container h1,
.main_container p {
  margin-bottom: 15px; /* Espacio entre los elementos */
  text-align: center;
}

/* Estilo para el contenedor interno */
.inner_container {
  display: flex; /* Flex para alinear los divs hijos en una fila */
  width: 100%; /* Ocupa el ancho completo del contenedor principal */
}

/* Estilo para los contenedores de la mitad de la pantalla */
.half_container {
  flex: 1; /* Ocupa la mitad del ancho del contenedor */
  display: flex;
  max-width: 48%;
  flex-direction: column; /* Ordena h2 y el div de forma descendente */
  align-items: center; /* Centra el contenido */
}

/* Estilo para el h2 dentro de cada half-container */
.half_container h2 {
  margin: 8px;
  padding: 8px;
  text-align: center;
}

/* Estilo para el content-box dentro de cada half-container */
.content_box {
  width: 100%; /* Ancho completo dentro del half-container */
  display: flex;
  justify-content: center; /* Centra horizontalmente el contenido */
}

/* Estilos para el contenedor principal */
.list_container {
  display: grid;
  grid-template-columns: repeat(
    auto-fill,
    100px
  ); /* Columnas de 100px de ancho, se ajustarán automáticamente */
  gap: 50px; /* Espacio entre cada item */
  width: 100%; /* Ocupa el 100% del ancho disponible */
  max-height: 800px; /* Altura máxima de 800px */
  overflow-y: auto; /* Desplazamiento vertical si el contenido excede la altura */
  box-sizing: border-box; /* Incluye padding en el cálculo del ancho */
}

/* Estilos para cada item */
.list_item {
  width: 125px;
  height: 185px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 14px;
  color: var(--text-color);
  border: 1px solid var(--liberty-blue);
  border-radius: 12px;
}

/* 
---------------------------------------------
---------------------------------------------

Media Query

---------------------------------------------
--------------------------------------------- 
*/

@media only screen and (max-width: 650px) {
  .inner_container {
    flex-direction: column; /* Coloca los half-containers uno encima del otro */
    align-items: center; /* Centra los elementos dentro del inner-container */
  }

  .half_container {
    width: 100%;
    max-width: 300px; /* Ancho máximo de 300px para cada half-container */
  }
}
