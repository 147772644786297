/* 
---------------------------------------------
QR Scanner
--------------------------------------------- 
*/

.item {
  width: 100px;
  height: 150px;
  text-align: center;
  margin: 10px;
}

.item img {
  width: 95px;
  height: 95px;
  border-radius: 50%;
  object-fit: cover;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
