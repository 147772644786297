/* 
---------------------------------------------
Event Item
---------------------------------------------
*/

.content {
  display: flex;
}

.item_image {
  margin: 10px;
  text-align: center;
}

.item_image img {
  width: 400px;
  height: 550px;
  background-image: url("./../../../../public/img/background.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  object-fit: cover;
  border-radius: 16px;
}

.info_container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.info_container h2,
h3 {
  margin: 0;
  padding: 0;
  text-align: justify;
}

.info_container p {
  text-align: justify;
}

.map {
  height: 225px;
  width: 100%;
  background-color: var(--white-action);
}

.location_container {
  display: flex;
}

.location_content {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Alinea contenido del div a la izquierda */
  margin-right: 10px; /* Espacio entre los dos divs */
}

.location_content:last-child {
  margin-right: 0; /* Elimina el margen derecho del último div */
}

.location_content div,
.location_content p {
  margin-bottom: 10px; /* Espacio entre los elementos dentro del div */
}

.location_content p {
  font-weight: lighter;
}

/* 
---------------------------------------------
---------------------------------------------

Media Query

---------------------------------------------
--------------------------------------------- 
*/

/* Media query para dispositivos con un ancho máximo de 768px (típicamente dispositivos móviles) */
@media only screen and (max-width: 650px) {
  .content {
    flex-direction: column;
  }

  .item_image img {
    width: 250px;
    height: 400px; /* Ajusta la altura de la imagen para que no ocupe demasiado espacio vertical */
  }

  .map {
    height: 150px; /* Ajusta la altura del mapa para dispositivos móviles */
  }
}