.button_with_icon {
  display: flex; /* Flexbox to align items */
  align-items: center; /* Center items vertically */
  justify-content: center; /* Center items horizontally */
  padding: 0 10px; /* Space between content and edges */
  background-color: white;
  border: 2px solid var(--liberty-blue);
  border-radius: 12px; /* Rounded corners */
  cursor: pointer; /* Pointer cursor on hover */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  transition: all 0.3s ease; /* Smooth hover effect */
  margin: 5px;
}

.button_with_icon:hover {
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.439);
}

.button_icon {
  height: 48px; /* Fixed height for the icon */
  width: 48px; /* Fixed width for the icon */
  margin-right: 10px; /* Space between icon and text */
  object-fit: contain; /* Ensure the icon fits inside the box */
}

.button_text {
  font-size: 16px; /* Adjust text size */
  font-weight: bold; /* Bold text for emphasis */
  color: var(--liberty-blue);
  white-space: nowrap; /* Prevent text from wrapping */
}
