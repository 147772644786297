.container {
  margin: 20px; /* Espaciado alrededor del div */
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Espaciado para que la imagen se alinee abajo */
  height: 400px; /* Puedes ajustar la altura según sea necesario */
  width: 400px;
  border: 1px solid #ddd;
  border-radius: 8px;
}

.image {
  width: 100%; /* Ajusta el ancho de la imagen al div */
  max-height: 150px; /* Define una altura máxima para que la imagen no sea muy grande */
  object-fit: contain; /* Asegura que la imagen se ajuste bien dentro del espacio disponible */
  margin-top: 10px; /* Espacio extra entre la imagen y el contenido */
}
