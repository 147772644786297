/* 
---------------------------------------------
Profile
--------------------------------------------- 
*/

.content {
  width: 100%; /* Ancho completo de la pantalla */
  height: 100%; /* Alto completo de la pantalla */
}

.buttons_content {
  display: flex;
  align-items: center; /* Alinea verticalmente los botones */
  justify-content: space-between; /* Alinea el primer grupo de botones a la izquierda y el último botón a la derecha */
  height: 50px; /* Altura específica del div */
}

.buttons_group {
  display: flex; /* Alinea los botones Públicos y Privados juntos */
  gap: 10px; /* Espacio entre los botones dentro del grupo */
}

.profile_content {
  display: flex; /* Habilita Flexbox */
  flex-direction: column; /* Organiza los hijos en una columna */
  justify-content: center; /* Centra los hijos verticalmente */
  align-items: center; /* Centra los hijos horizontalmente */
  margin: 50px auto;
  height: 100%;
  max-width: 1080px;
  border-radius: 16px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.inner_content {
  display: flex;
  margin: 25px;
}

.left_content {
  display: flex;
  flex-direction: column;
  justify-content: baseline; /* Centra verticalmente los elementos */
  align-items: center; /* Centra horizontalmente los elementos */
  margin-right: 16px;
}

.left_content img {
  width: 250px;
  height: 250px;
  margin: 10px;
  object-fit: cover;
  border-radius: 50%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.right_content {
  flex-grow: 1; /* El div se expande para ocupar el espacio restante */
  color: var(--text-color);
  width: 100%;
  margin-left: 25px;
}

.right_content label {
  font-weight: lighter;
}

.right_content input {
  font-size: large;
  padding: 10px;
}

.gender_select {
  width: 100%;
  padding: 12px 5px;
  margin: 4px 0px;
  box-sizing: border-box;
  border: 2px solid var(--liberty-blue);
}

/*
---------------------------------------------
---------------------------------------------

Media Query

---------------------------------------------
--------------------------------------------- 
*/

/* Media query para dispositivos  móviles */
@media only screen and (max-width: 650px) {
  .profile_content {
    margin: 10px auto;
    height: 100%;
    width: 98%;
    box-shadow: none;
  }

  .inner_content {
    flex-direction: column; /* Cambia la disposición a vertical */
  }

  .left_content,
  .right_content {
    margin-bottom: 15px;
    margin: 0;
  }

  .left_content img {
    width: 200px; /* Opcional: Ajusta el tamaño de la imagen para dispositivos más pequeños */
    height: 200px; /* Opcional: Ajusta el tamaño de la imagen para dispositivos más pequeños */
  }
}
