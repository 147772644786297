/* 
---------------------------------------------
Event Information
--------------------------------------------- 
*/

.container {
  display: flex; /* Habilita Flexbox */
  flex-direction: column; /* Organiza los hijos en una columna */
  justify-content: center; /* Centra los hijos verticalmente */
  align-items: center; /* Centra los hijos horizontalmente */
}

.info_container {
  width: 650px;
  height: auto;
  padding: 20px;
  margin: 10px;
  border-radius: 16px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.info_container p {
  font-style: italic;
  font-weight: lighter;
}

.info_container input,
select {
  font-size: 22px;
}

.type_select {
  width: 100%;
  padding: 12px 5px;
  margin: 4px 0px;
  box-sizing: border-box;
  border: 2px solid var(--liberty-blue);
}

.unlimited_container {
  display: flex;
  flex-direction: column;
}

.check_box {
  display: flex;
  align-items: center;
}

.check_box button {
  background-color: transparent;
}

/*
---------------------------------------------
---------------------------------------------

Media Query

---------------------------------------------
--------------------------------------------- 
*/

/* Media query para dispositivos  móviles */
@media only screen and (max-width: 650px) {
  .info_container {
    width: 98%;
    margin: 0 auto; /* Centra el formulario horizontalmente */
    padding: 0;
    box-shadow: none;
  }
}