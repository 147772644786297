/* 
---------------------------------------------
Map filter
---------------------------------------------
*/

.content {
  width: 100%;
  min-height: 500px;
  align-items: center;
  justify-content: center;
}

.buttons {
  display: flex;
  align-items: center; /* Alinea verticalmente los botones */
  justify-content: space-between; /* Alinea el primer grupo de botones a la izquierda y el último botón a la derecha */
  height: 50px; /* Altura específica del div */
}

.loader_wrapper {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  display: flex; /* Habilita Flexbox */
  align-items: center; /* Centra los elementos verticalmente */
  justify-content: center; /* Centra los elementos horizontalmente */
}

.loader_content {
  width: 600px;
  text-align: center;
  margin: 20px;
  padding: 20px;
  border-radius: 16px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.loader_content p {
  font-weight: lighter;
}

.loader_content img {
  width: 200px;
  height: 200px;
}

.map_wrapper {
  display: flex; /* Utiliza flexbox para alinear los elementos */
  height: 500px;
  margin: 30px;
  padding: 20px;
  border-radius: 16px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.map_filter {
  flex: 3;
  margin: 5px;
}

.list_wrapper {
  flex: 1;
  overflow-y: auto; /* Habilita el desplazamiento vertical cuando el contenido excede la altura máxima */
  border: 1px solid var(--liberty-blue);
  margin: 8px;
  padding: 12px;
  border-radius: 12px;
}

.list_content {
  margin: 10px;
  padding: 12px;
  border-radius: 16px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
}

.modal {
  width: 100%;
  height: auto;
  position: relative; /* Posicionamiento relativo para que los elementos dentro se posicionen respecto a este div */
  color: var(--white-action);
}

.modal img {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Para asegurar que la imagen se ajuste dentro del contenedor */
}

.modal_content {
  position: absolute; /* Posicionamiento absoluto para que los elementos de contenido se posicionen sobre la imagen */
  height: 100%;
  width: 100%;
  top: 50%; /* Desplazamiento hacia abajo al 50% del contenedor */
  left: 50%; /* Desplazamiento hacia la derecha al 50% del contenedor */
  transform: translate(-50%, -50%); /* Centrar el contenido del div */
  text-align: left;
  overflow: auto; /* Habilita el scroll si el contenido excede las dimensiones del div */
}

.modal_content_info {
  color: var(--white-action);
}

.modal_content_info h1 {
  font-size: 42px; /* Tamaño más grande para el h1 */
}

.modal_content_info h2 {
  font-size: 28px; /* Tamaño más pequeño para el h2 */
  margin: 0;
  padding: 0;
  color: var(--white-action);
}

.modal_content_info p {
  text-align: justify; /* Opcional: Justifica el texto del párrafo */
  color: var(--white-action);
}

.modal_location h2 {
  color: var(--white-action);
}

.modal_location p {
  color: var(--white-action);
}

.modal_map {
  width: 100%;
  height: 250px;
}

.modal_profile_info {
  text-align: center;
}

.modal_profile_info img {
  width: 175px;
  height: 175px;
  object-fit: cover;
  border-radius: 50%;
}

.modal_profile_info h2 {
  text-align: center;
  color: var(--white-action);
}

.modal_profile_info h3 {
  font-size: 32px;
  text-align: center;
  color: var(--white-action);
}

/* 
---------------------------------------------
---------------------------------------------

Media Query

---------------------------------------------
--------------------------------------------- 
*/

/* Media query para dispositivos con un ancho máximo de 768px (típicamente dispositivos móviles) */
@media only screen and (max-width: 650px) {
  .map_wrapper {
    height: 1000px;
    margin: 0 auto;
    padding: 0 auto;
    box-shadow: none;
    flex-direction: column; /* Alinea los hijos verticalmente en dispositivos pequeños */
  }
}