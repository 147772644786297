/* 
---------------------------------------------
Invitations
--------------------------------------------- 
*/

.container {
  width: 100%; /* Ancho completo de la pantalla */
  height: 100%; /* Alto completo de la pantalla */
}

.container h2 {
  color: var(--text-color);
  font-size: 28px;
  padding: 0;
  margin: 0;
  margin-bottom: 12px;
}

.list {
  min-height: 400px;
  overflow-y: auto; /* Scroll vertical si es necesario */
  flex-wrap: wrap; /* Envuelve los elementos si no caben en una sola fila */
  display: flex; /* Habilita Flexbox */
  align-items: center; /* Centra los elementos verticalmente */
  justify-content: center; /* Centra los elementos horizontalmente */
}

.empty_content {
  width: 600px;
  text-align: center;
  margin: 20px;
  padding: 20px;
  border-radius: 16px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.empty_content p {
  font-weight: lighter;
}

.empty_content img {
  width: 200px;
  height: 200px;
}

.modal {
  width: 100%;
  height: auto;
  position: relative; /* Posicionamiento relativo para que los elementos dentro se posicionen respecto a este div */
  color: var(--white-action);
}

.modal img {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Para asegurar que la imagen se ajuste dentro del contenedor */
}

.modal_content {
  position: absolute; /* Posicionamiento absoluto para que los elementos de contenido se posicionen sobre la imagen */
  height: 100%;
  width: 100%;
  top: 50%; /* Desplazamiento hacia abajo al 50% del contenedor */
  left: 50%; /* Desplazamiento hacia la derecha al 50% del contenedor */
  transform: translate(-50%, -50%); /* Centrar el contenido del div */
  text-align: left;
  overflow: auto; /* Habilita el scroll si el contenido excede las dimensiones del div */
}

.modal_info {
  color: var(--white-action);
}

.modal_info h1 {
  font-size: 42px; /* Tamaño más grande para el h1 */
}

.modal_info h2 {
  font-size: 28px; /* Tamaño más pequeño para el h2 */
  margin: 0;
  padding: 0;
  color: var(--white-action);
}

.modal_info p {
  text-align: justify; /* Opcional: Justifica el texto del párrafo */
  color: var(--white-action);
}

.modal_location h2 {
  color: var(--white-action);
}

.modal_location p {
  color: var(--white-action);
}

.modal_map {
  width: 100%;
  height: 250px;
}

.modal_profile_info {
  text-align: center;
}

.modal_profile_info img {
  width: 175px;
  height: 175px;
  object-fit: cover;
  border-radius: 50%;
}

.modal_profile_info h2 {
  text-align: center;
  color: var(--white-action);
}

.modal_profile_info h3 {
  font-size: 32px;
  text-align: center;
  color: var(--white-action);
}
