/* 
---------------------------------------------
Searching
--------------------------------------------- 
*/

.searching {
  width: 600px;
  text-align: center;
  margin: 20px;
  padding: 20px;
  border-radius: 16px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.searching p {
  font-weight: lighter;
}

.searching img {
  width: 200px;
  height: 200px;
}

/* 
---------------------------------------------
---------------------------------------------

Media Query

---------------------------------------------
--------------------------------------------- 
*/

/* Media query para dispositivos con un ancho máximo de 768px (típicamente dispositivos móviles) */
@media only screen and (max-width: 650px) {
  .searching {
    width: 400px;
  }
}