/* 
---------------------------------------------
My events
--------------------------------------------- 
*/

.content {
  width: 100%; /* Ancho completo de la pantalla */
  height: 100%; /* Alto completo de la pantalla */
}

.buttons {
  display: flex;
  align-items: center; /* Alinea verticalmente los botones */
  justify-content: space-between; /* Alinea el primer grupo de botones a la izquierda y el último botón a la derecha */
  height: 50px; /* Altura específica del div */
}

.buttons_group {
  display: flex; /* Alinea los botones Públicos y Privados juntos */
  gap: 10px; /* Espacio entre los botones dentro del grupo */
}

.list {
  min-height: 400px;
  overflow-y: auto; /* Scroll vertical si es necesario */
  flex-wrap: wrap; /* Envuelve los elementos si no caben en una sola fila */
  display: flex; /* Habilita Flexbox */
  align-items: center; /* Centra los elementos verticalmente */
  justify-content: center; /* Centra los elementos horizontalmente */
}

.empty_wrapper {
  width: 600px;
  text-align: center;
  margin: 20px;
  padding: 20px;
  border-radius: 16px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.empty_wrapper p {
  font-weight: lighter;
}

.empty_wrapper img {
  width: 200px;
  height: 200px;
}

.event_item {
  display: flex;
  flex-direction: column;
  width: 100%;
  border: 1px solid var(--liberty-blue);
  border-radius: 16px;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 24px;
}

.event_item button {
  margin-left: 10px; /* Espacio entre botones */
}

.item_buttons {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
}
