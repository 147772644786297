/* 
---------------------------------------------
Search Guest
--------------------------------------------- 
*/

.content {
  width: 100%; /* Ancho completo de la pantalla */
  height: 100%;
}

.border_content {
  border: 1px solid var(--liberty-blue);
  margin: 8px;
  padding: 12px;
  border-radius: 12px;
}

.selected_list {
  overflow-x: auto; /* Scroll horizontal si es necesario */
  display: flex; /* Utiliza flexbox para alinear los elementos */
  text-align: center;
}

.small_item {
  border: 1px solid var(--liberty-blue);
  padding-bottom: 8px;
  border-radius: 12px;
  margin-left: 8px;
  margin-right: 8px;
}

.selected_content {
  margin: 16px;
  padding: 16px;
  border-radius: 16px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
}

.buttons_content {
  display: flex;
  align-items: center; /* Alinea verticalmente los botones */
  justify-content: space-between; /* Alinea el primer grupo de botones a la izquierda y el último botón a la derecha */
  height: 50px; /* Altura específica del div */
}

.button_group {
  display: flex; /* Alinea los botones Públicos y Privados juntos */
  gap: 10px; /* Espacio entre los botones dentro del grupo */
}

.list_container {
  display: flex; /* Activa Flexbox */
  flex-direction: column; /* Organiza los hijos en una columna */
  justify-content: center; /* Centra los elementos verticalmente */
  align-items: center; /* Centra los elementos horizontalmente */
  height: 100%; /* Opcional: Ajusta la altura si necesitas que ocupe un espacio específico */
  width: 100%; /* Asume que quieres que ocupe todo el ancho disponible */
}

.guest_list {
  width: 100%;
  height: 100%;
  overflow-y: auto; /* Scroll vertical si es necesario */
  display: flex; /* Utiliza flexbox para alinear los elementos */
  flex-wrap: wrap; /* Envuelve los elementos si no caben en una sola fila */
  text-align: center;
  margin: 0;
  padding: 0;
}

.guest_item {
  width: 200px;
  height: 300px;
  text-align: center;
  margin: 8px;
  padding: 12px;
  border-radius: 12px;
  border: 1px solid var(--liberty-blue);
}

.empty_list {
  width: 600px;
  text-align: center;
  margin: 20px;
  padding: 20px;
  border-radius: 16px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.empty_list p {
  font-weight: lighter;
}

.empty_list img {
  width: 200px;
  height: 200px;
}

/* 
---------------------------------------------
---------------------------------------------

Media Query

---------------------------------------------
--------------------------------------------- 
*/

/* Media query para dispositivos con un ancho máximo de 768px (típicamente dispositivos móviles) */
@media only screen and (max-width: 650px) {
  .empty_list {
    width: 400px;
  }
}